* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Gudea', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #2d3c4e;
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}
